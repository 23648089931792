import React from 'react';
import {getRoutes} from "../../components/shared/routes";
import Layout from '../../components/shared/Layout';
import SEO from '../../components/shared/SEO';
import css from "./sawmill_trader.module.scss";
import AdditionalInformation
    from "../../components/shared/additionalInformation/additionalInformation";
import Footer from "../../components/shared/Footer/Footer";
import {graphql, useStaticQuery} from "gatsby";
import {getImagesFluid, renderImageByFileName} from "../../components/componentsUtils";
import BackToTraceabilityButton from "../../components/shared/ButtonBackTo/BackToTraceabilityButton";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sawmill_trader"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const SawmillTraderPage = () => {
    const routes = getRoutes();
    const pageTitle = routes.TraceabilitySawmillTrader.pageTitle;
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const sectionAnchor = routes.Traceability.sections.sawmillTrader.anchor

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <Breadcrumbs
                title={routes.TraceabilitySawmillTrader.pageTitle}
                parentTitles={[routes.Traceability.pageTitle]}
                parentRoutes={[routes.Traceability]}
                sectionAnchors={[sectionAnchor]}
            />

            <div className={css.wrapper}>
                <div className={css.title}>
                    {routes.TraceabilitySawmillTrader.pageTitle}
                </div>
                <div className={css.imageSectionWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'entry.png', 'sawmill trader')}
                    </div>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'log_yard_3.png', 'sawmill trader')}
                    </div>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'log_yard_4.png', 'sawmill trader')}
                    </div>
                </div>

                <div className={css.text}>
                    <div>Single log identification allows to segregate inventory by provenance, including geo-location
                        and harvesting date positively impacts sales and stock turn over time and inventory management.
                        <br/><br/>
                        In case of non-compliant delivery an obligation of separation of questioned timber logs for
                        official audit must be performed.
                    </div>
                </div>
                <BackToTraceabilityButton sectionAnchor={sectionAnchor}/>

                <AdditionalInformation/>

                <Footer/>

            </div>
        </Layout>
    );
};

export default SawmillTraderPage;
